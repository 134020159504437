import dynamic from "next/dynamic";
import React from "react";
import { AdSlot } from "react-dfp";

import styles from "./Advertisement.module.scss";

export const Advertisement: React.FC<{ position: number }> = ({ position }) => {
  const sizes = window.innerWidth < 768 ? [300, 400] : [693, 200];
  const portal = process.env.NEXT_PUBLIC_PORTAL;

  return (
    <div className={styles.wrapper}>
      <AdSlot
        adUnit={`newoncev3_${
          portal === "sport" ? "sport_" : ""
        }feed${position}`}
        sizes={[sizes, [1, 1]]}
      />
    </div>
  );
};

export default dynamic(() => Promise.resolve(Advertisement), { ssr: false });
