import useSWRInfinite from "swr/infinite";

import { fetchPaginated, fetchPaginatedFromV2API } from "@/helpers/apiHelpers";

export const usePagination = (
  items: any[],
  length: number,
  getKey: (index: number) => string,
  apiVersion: number = 1
) => {
  const { data, error, size, setSize, mutate } = useSWRInfinite(
    getKey,
    apiVersion === 2 ? fetchPaginatedFromV2API : fetchPaginated,
    { fallbackData: items, revalidateOnMount: true }
  );

  const elements = data ? [].concat(...data) : [];
  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < length);

  const loadMore = () => {
    setSize(size + 1);
  };

  return {
    elements,
    isLoadingInitialData,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    loadMore,
    mutate,
    error,
  };
};
