import React from "react";
import classNames from "classnames";

import Typography, { Variant } from "@/components/Typography";
import { ClassName } from "@/helpers/pageHelpers/Common/interface";

import styles from "./SectionHeading.module.scss";

export const SectionHeading: React.FC<{
  title: string;
  component?: Variant;
  className?: ClassName;
}> = ({ title, component = "h6", className }) => (
  <Typography
    component={component}
    variant="heading"
    className={classNames(styles.heading, className)}
  >
    {title}
  </Typography>
);

export default SectionHeading;
