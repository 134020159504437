import { TagInterface } from "@/helpers/pageHelpers/Common/interface";

export const internalTags: string[] = ["otwarty", "premium"];

export const getDisplayableTags = (
  tags: TagInterface | TagInterface[]
): TagInterface | TagInterface[] | undefined => {
  if (tags instanceof Array) {
    return tags.filter((tag) => !internalTags.includes(tag.title));
  } else {
    return !internalTags.includes(tags?.title) ? tags : undefined;
  }
};
