import axios from "axios";

import client, { apiClientV2 } from "@/helpers/apiClient";
import { UIStore } from "@/store/UIStore";

export const fetcher = (url) => client(url).then((res) => res.data);

export const fetchPaginated = (url) =>
  client(url).then((res) => res.data.items);

export const fetchPaginatedFromV2API = (url) =>
  apiClientV2()(url).then((res) => res.data.items);

export const fetchPaginatedComments = (url) =>
  client(url).then((res) => {
    UIStore.update((store) => {
      store.commentsCount = res.data.itemsCount;
    });

    return res.data.items;
  });

export const localApiClient = (url) => axios.get(url).then((res) => res.data);
