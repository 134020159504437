import { ReactNode } from "react";

import { FeedTypes } from "@/helpers/pageHelpers/Common/interface";
import ArticleTile from "@/components/FeedTiles/ArticleTile";
import PodcastTile from "@/components/FeedTiles/PodcastTile";
import Advertisement from "@/components/Advertisement";
import { getDisplayableTags } from "@/helpers/getDisplayableTags";

export const renderFeedElement = (
  type: FeedTypes,
  object: any,
  adIndex?: number,
  index?: number
): ReactNode => {
  if (!object) {
    return null;
  }

  switch (type) {
    case FeedTypes.Article:
      return (
        <ArticleTile
          key={object.id}
          from="Feed"
          {...object}
          tag={getDisplayableTags(object.tag)}
          index={index}
          variant="medium"
        />
      );
    case FeedTypes.Episode:
      return <PodcastTile isDark index={index} key={object.id} {...object} />;
    case FeedTypes.Ad:
      return <Advertisement key={adIndex} position={adIndex} />;
    default:
      return null;
  }
};
