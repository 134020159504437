import { AdSlot } from "react-dfp";
import classNames from "classnames";

import { SidebarInterface } from "@/components/Advertisement/Sidebar/Sidebar.interface";

import styles from "../Advertisement.module.scss";

export const SidebarAd: React.FC<SidebarInterface> = ({ type }) => {
  const isClientSide = typeof window !== "undefined";
  const isDesktop = isClientSide && window.innerWidth >= 1240;

  return isDesktop ? (
    <div className={classNames(styles.sidebar, styles[type])}>
      <AdSlot
        adUnit={`newoncev3_sidebar1`}
        sizes={[
          [300, 250],
          [300, 400],
          [300, 600],
        ]}
      />
    </div>
  ) : null;
};

export default SidebarAd;
