import React from "react";
import Link from "next/link";
import Image from "next/image";

import PageWrapper from "@/components/PageWrapper";
import Container from "@/components/Container";
import Typography from "@/components/Typography";
import Button from "@/components/Button";
import { routePath } from "@/helpers/routePath";
import MetaHead from "@/components/MetaHead";
import { defaultOGImage } from "@/helpers/pageMetaData";
import QuestionMarkIcon from "@/icons/question-mark.svg";
import { getImgixURL } from "@/helpers/getImgixURL";

import styles from "./Error.module.scss";

const Custom404: React.FC = () => {
  //TODO: update with endpoint data
  const metaData = {
    title: "404",
    description:
      "Wczytuj się w artykuły, dzięki którym lepiej zrozumiesz, wokół czego kręci się świat kultury popularnej, wsłuchuj się w audycje radiowe na żywo w opcji 24/7 i ekscytuj się tysiącami godzin podcastów - to jakieś pół roku słuchania bez przerwy! Dodatkowo, dowiaduj się więcej o artystach, korzystając z naszej bazy muzycznej.",
    ogFbTitle: "newonce - 404",
    ogFbImage: defaultOGImage,
  };

  const pageData = { headerLabel: "404" };
  const isClientSide = typeof window !== "undefined";
  const showForm = () => {
    if (isClientSide) {
      window?.zE?.activate();
    }
  };

  return (
    <PageWrapper pageData={pageData}>
      <div className={styles.wrapper}>
        <MetaHead metaData={metaData} />
        <Container className={styles.container}>
          <Image
            src={getImgixURL(
              "https://cdn.newonce.me/uploads/images/46404/Hobbies_Plant.png"
            )}
            layout="fixed"
            width={160}
            height={160}
            alt="not found"
          />

          <Typography variant="h4" component="h3" className={styles.heading}>
            Coś poszło nie tak...
          </Typography>
          <Typography variant="p" component="p" className={styles.desc}>
            Staraliśmy się znaleźć wybraną stronę, ale coś nie zadziałało.
            Spróbuj odświeżyć stronę lub przejdź na stronę główną.
          </Typography>

          <Link href={routePath.FEED.href}>
            <a className={styles.link}>
              <Button variant="secondaryStroke" className={styles.button}>
                Wróć na stronę główną
              </Button>
            </a>
          </Link>
        </Container>
      </div>
      <Typography variant="medium" className={styles.help}>
        <QuestionMarkIcon className={styles.helpIcon} />
        <span>
          Daj nam znać
          <button className={styles.helpButton} onClick={showForm}>
            tutaj
          </button>
          jeśli problem się powtarza. Będziemy mogli dużo szybciej naprawić nasz
          błąd.
        </span>
      </Typography>
    </PageWrapper>
  );
};

export default Custom404;
