import { useEffect } from "react";
import { useStoreState } from "pullstate";
import dynamic from "next/dynamic";
import classnames from "classnames";

import Button from "@/components/Button";
import SkeletonLoader from "@/components/SkeletonLoader";
import { renderFeedElement } from "@/containers/Feed/renderFeedElement";
import { apiPath } from "@/helpers/apiPath";
import { FeedTypes } from "@/helpers/pageHelpers/Common/interface";
import { TagPageInterface } from "@/helpers/pageHelpers/Tag/interface";
import { UIStore } from "@/store/UIStore";
import Container from "@/components/Container";
import { GridColumns } from "@/components/Container/Container.interface";
import SidebarAd from "@/components/Advertisement/Sidebar";
import SectionHeading from "@/components/SectionHeading";
import { usePagination } from "@/helpers/usePagination";
import { defaultPageItems } from "@/helpers/apiPathHelpers";

import styles from "../Feed.module.scss";

const PopularArticles = dynamic(import("@/components/PopularArticles"), {
  ssr: false,
});

const TagList: React.FC<TagPageInterface> = ({
  query,
  initialData,
  isFeed,
}) => {
  const {
    mutate,
    elements,
    isLoadingMore,
    isReachingEnd,
    isLoadingInitialData,
    loadMore,
  } = usePagination(
    initialData?.feed,
    defaultPageItems.SPECIFIC_FEED,
    (index: number) => apiPath.FEED_TAGS({ slug: query, page: index + 1 }),
    2
  );

  let advertisementIndex = 0;
  const { userLoggedIn } = useStoreState(UIStore, (store) => store);
  const isClientSide = typeof window !== "undefined";
  const isDesktop = isClientSide && window.innerWidth >= 1240;

  useEffect(() => {
    mutate();
  }, [userLoggedIn, mutate]);

  return (
    <Container grid={GridColumns.Twelve}>
      <div className={classnames(styles.feedTiles, isFeed && styles.tiles)}>
        {isFeed && <SectionHeading title="Najnowsze" component="h1"/>}
        {elements.map((element, index) => {
          if (element.type === FeedTypes.Ad) {
            advertisementIndex++;
          }

          return renderFeedElement(
            element.type,
            element.object,
            advertisementIndex,
            index
          );
        })}

        {isLoadingMore && <SkeletonLoader />}

        {!isReachingEnd && !isLoadingInitialData && (
          <Button
            variant="secondary"
            onClick={loadMore}
            className={styles.loadMore}
          >
            {isLoadingMore ? "Wczytuję..." : "Wczytaj więcej"}
          </Button>
        )}
      </div>

      {isDesktop && (
        <aside
          className={classnames(
            styles.popular,
            isFeed && styles.popularArticles
          )}
        >
          <PopularArticles />
          <SidebarAd />
        </aside>
      )}
    </Container>
  );
};

export default TagList;
